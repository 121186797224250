import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import Img from "gatsby-image"

const Expertise = () => {
    
    const data = useStaticQuery(graphql`
    query {
      Expertise: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Expertise" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
    return (
        
        <Layout>
      <HomeLayout>
        {data.Expertise.nodes.map(img => (
          <Img fluid={img.childImageSharp.fluid} alt="Expertise images" key={img.id}/>
        ))}
      </HomeLayout>
    </Layout>
    );
};

export default Expertise;